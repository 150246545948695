#emailsAfterSaveList {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;

    & > li {
        position: relative;
    }


}

.emailsAfterSaveList {
    &__item {
        &-remove {
            position: absolute;
            cursor: pointer;
            padding: 5px;
            top: 0;
            right: 0;
            font-size: 11px;
            text-transform: uppercase;

            * {
                pointer-events: none;
            }
        }
    }
}
