.order-single {
    font-family: "Inter", sans-serif;

    &__card {
        &-left {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            flex-grow: 2;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin-bottom: 30px;

        &-icon {
            font-size: 28px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            margin-right: 16px;
        }

        &-left {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
        }

        &-text {
            text-align: left;

            &--small {
                font-size: 16px;
                line-height: normal;
                margin: 0;
            }
        }
    }

    &__status {
        position: relative;

        &-title {
            border: 2px solid #CED4DA;
            width: 185px;
            height: 32px;
            padding: 8px 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;
            z-index: 6;
            background-color: #ffffff;
            user-select: none;

            * {
                pointer-events: none;
            }
        }

        &-list {
            position: absolute;
            height: 0;
            overflow: hidden;
            background-color: #ffffff;
            top: calc(100% - 2px);
            border: 2px solid #CED4DA;
            border-top: none;
            padding: 0;
            z-index: 3;

            * {
                pointer-events: all;
            }
        }

        &-item {
            font-size: 12px;
            text-transform: uppercase;
            padding: 2px 16px;
            cursor: pointer;

            &.active {
                background-color: rgba(0, 0, 0, 0.2);
                font-weight: 700;
                cursor: default;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        &-close {
            display: none;
        }

        &.open {
            .order-single__status {
                &-list {
                    height: auto;
                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
                    padding: 6px 0;
                }

                &-close {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                    display: block;
                }
            }
        }

        &.loading {
            .order-single__status {
                &-list {
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(255, 255, 255, 0.7);
                        cursor: progress;
                    }
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        &-separator {
            width: 2px;
            height: 27px;
            background-color: #CED4DA;
            margin: 0 15px;
        }
    }


    &__date {
        font-size: 14px;
        font-weight: 400;

    }

    &__delivery {
        background-color: transparent;

        &-head,
        &-body {
            background-color: #ffffff;
        }

        &-name,
        &-price {
            border: 1px solid #dee2e6 !important;
            font-size: 14px;
            font-weight: 400;
        }

        &-price {
            width: 130px;
        }

        &-title {
            font-size: 14px;
            font-weight: 700;
            border: 1px solid #dee2e6 !important;
        }
    }

    &__summary {
        background-color: #ffffff;
        border: none;
        width: auto;
        margin-left: auto;
        margin-right: 0;

        &-head {
            border: 1px solid #dee2e6 !important;
        }

        &-text,
        &-value {
            border: 1px solid #dee2e6 !important;
        }

        &-text {
            padding: 18px 16px 18px 33px !important;
        }

        &-value {
            padding: 18px 27px 18px 16px !important;
        }
    }

    &__address {
        &-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            text-align: center;
            padding: 12px 16px;
            color: #000000;

            &:after, &:before {
                display: none;
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 700;
        }

        &-edit {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            width: 105px;
            height: 31px;
            font-size: 12px;
            letter-spacing: 0.05em;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            border: 2px solid #CED4DA;
            border-radius: 3px;
            color: #000000;
            transition: background-color .2s ease-in-out;

            &:hover {
                color: #000000;
                background-color: #CED4DA;
                text-decoration: none;
            }

            i {
                margin-bottom: 3px;
                margin-right: 5px;
                font-size: 16px;
            }
        }

        &-item {
            &--status {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .order-list__item-text {
                    margin-bottom: 8px;
                    margin-right: 12px;
                }

                .order-list__item-status {
                    margin: 0 0 8px;
                }
            }
        }
    }

    &__products {
        border: none;

        &-quantity,
        &-index {
            text-align: center;
            width: 45px;
            padding: .75rem 5px !important;
        }

        &-price {
            width: 145px;
        }

        &-value {
            width: 135px;
        }

        &-head {
            th {
                border-bottom: 1px solid #dee2e6 !important;
            }
        }

        &-head,
        &-body {
            background-color: #ffffff;

            th, td {
                border: 1px solid #dee2e6;
            }
        }

        &-body {
            td {
                border-top: none;
            }
        }

        &-foot,
        &-summary {
            background-color: transparent;
            border: none;

        }

        &-summary {
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            padding: .75rem 45px .75rem 0 !important;
        }
    }
}