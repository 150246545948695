.file-uploader {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    &[data-mode="list"] {
        .file-uploader__wrap--mode {
            display: none;
            &[data-mode="list"] {
                display: block
            }
        }
        .file-uploader__top-btn[data-mode="list"] {
            background-color: #000000;
            color: #ffffff;
        }
        [data-theme="dark"] {
            .file-uploader__top-btn[data-mode="list"] {
                background-color: #ffffff;
                color: #1a1b1c;
            }
        }
    }
    &[data-mode="upload"] {
        .file-uploader__wrap--mode {
            display: none;
            &[data-mode="upload"] {
                display: block
            }
        }
        .file-uploader__top-btn[data-mode="upload"] {
            background-color: #000000;
            color: #ffffff;
        }
        [data-theme="dark"] {
            .file-uploader__top-btn[data-mode="upload"] {
                background-color: #ffffff;
                color: #1a1b1c;
            }
        }
    }
    &[data-open="true"] {
        display: block;
    }
    .file-uploader__add-folder {
        display: block;
        margin: 10px 0;
        position: relative;
        &-name {
            background-color: transparent;
            border: 1px solid #1a1b1c;
            height: 35px;
            padding-left: 5px;
            width: 100%;
            &::placeholder {
                opacity: 1;
            }
        }
        &-submit {
            position: absolute;
            top: 0;
            right: 0;
            height: 35px;
            outline: none;
            border: none;
            border-left: 1px solid #1a1b1c;
            width: 35px;
            background: transparent;
            font-weight: 700;
            box-sizing: border-box;
            font-size: 25px;
            padding-bottom: 7px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
        }
        &-ul {
            li {
                margin: 0;
                padding: 0;
            }
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        &-btn {
            width: 250px;
            height: 50px;
            border: 2px solid #000000;
            background-color: #ffffff;
            color: #000000;
            text-transform: uppercase;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            margin-right: 30px;
            border-radius: 3px;
            margin-bottom: 30px;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background-color: #000000;
                text-decoration: none;
                color: #ffffff;
            }
        }
        &-close {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 30px;
            cursor: pointer;
            &:after {
                content: "\f057";
                @extend .fa;
                @extend .fas;
            }
        }
    }
    .simplebar-track.simplebar-vertical {
        .simplebar-scrollbar {
            opacity: 1;
            &:before {
                opacity: 1;
                top: 0;
                bottom: 0;
                border-radius: 0;
            }
        }
    }
    &[data-loading="true"] {
        .file-uploader__wrap--main {
            &:before {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    &__scroll-wrap {
        height: calc(100% - 161px);
        width: calc(100% + 20px);
        margin: 0 -10px;
        padding-right: 20px;
    }
    &__col {
        padding: 0 15px;
        &--left {
            width: 100%;
            max-width: 300px;
            position: relative;
            max-height: 100%;
            &:after {
                content: "";
                background-color: #F3F3F3;
                position: absolute;
                left: 15px;
                right: 15px;
                top: 0;
                bottom: 0;
                border: 1px solid rgba(112,112,112,0.26);
            }
        }
        &--right {
            width: 100%;
            max-width: calc(100% - 300px);
            height: 100%;
        }
    }
    &__row {
        height: calc(100% - 80px);
    }

    &__upload {
        @extend .row;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        &-left {
            padding: 0 15px;
            width: 100%;
            max-width: calc(100% - 300px);
        }
        &-wrap {
            border: 2px dashed #707070;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            position: relative;
        }
        &-right {
            width: 100%;
            max-width: 300px;
            padding: 0 15px;
            height: 100%;
        }
        &-description {
            font-size: 18px;
            font-weight: 500;
        }
        &-info {
            font-size: 16px;
            opacity: 0.8;
        }
        &-counter {
            margin-bottom: 10px;
        }
        &-list {
            margin: 0;
            list-style: none;
            padding: 0;
            height: calc(100% - 58px);
            overflow: auto;
            &-item {
                font-size: 14px;
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                padding-left: 25px;
                position: relative;
                &:before {
                    @extend .fa;
                    @extend .fas;
                    content: "";
                    width: 20px;
                    height: 20px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    margin-right: 5px;
                    background-image: url("/admin/images/svg/loader.svg");
                    background-position: center;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    font-size: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &.icon-cancel {
                    &:before {
                        background-image: none;
                        content: "\f00d";
                        color: red;
                    }
                }
                &.icon-save {
                    &:before {
                        background-image: none;
                        content: "\f00c";
                        color: #28a745;
                    }
                }
            }
        }
        &-icon {
            margin-bottom: 20px;
            &:after {
                @extend .fa;
                @extend .fas;
                content: "\f382";
                font-size: 155px;
            }
        }
        &-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            opacity: 0;
        }
        &-progress {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            border: 1px solid white;
            height: 45px;
            position: relative;
            &-wrap {
            }
            &-text {
                margin-bottom: 10px;

            }
            &-bar {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #28a745;
                transition: all .2s ease-in-out;
            }
            &-counter {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 24px;
                font-weight: 600;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                z-index: 3;
            }
        }
    }
    &[data-upload="false"] {
        .file-uploader__upload {
            &-right {
                display: none;
            }
            &-progress {
                &-wrap {
                    display: none;
                }
            }
        }
    }
    &[data-upload="true"] {
        .file-uploader__upload {
            &-right {
                display: block;
            }
            &-progress {
                &-wrap {
                    width: 100%;
                    display: block;
                }
            }
            &-description {
                display: none;
            }
            &-input {
                display: none;
            }
        }
    }
    &[data-upload="uploaded"] {
        .file-uploader__upload {
            &-progress {
                &-wrap {
                    display: none;
                }
            }
        }
    }
    &__list-limit {
        position: relative;
        max-width: 120px;
        margin-left: auto;
        margin-right: 0;
        width: 100%;
        border-radius: 3px;
        select {
            border: 2px solid #000000;
            height: 49px;
            border-radius: 3px;
            width: 100%;
            padding-left: 15px;
        }
        &:after {
            content: "\f0d7";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 49px;
            background-color: #000000;
            pointer-events: none;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            color: #ffffff;
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            @extend .fa;
            font-size: 20px;
        }
    }
    &__finder {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

        &-input {
            border: 2px solid #000000;
            border-radius: 3px;
            height: 49px;
            padding: 0 19px;
            margin-right: 8px;
            background-color: transparent;
            color: inherit;
            &::placeholder {
                opacity: 1;
            }
        }
        &-submit {
            border-radius: 3px;
            border: 2px solid #000000;
            width: 49px;
            height: 49px;
            background-color: #000000;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            cursor: pointer;
            &:after {
                @extend .fas;
                content: "\f002";
                color: #ffffff;
                font-size: 20px;
            }
        }
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin-right: 8px;
            &[data-open="false"] {

                .file-uploader__input-wrap,
                .file-uploader__finder-submit {
                    display: none;
                }
                [data-open="false"] {
                    display: flex;
                }
                [data-open="true"] {
                    display: none;
                }
            }
            &[data-open="true"] {
                [data-open="true"] {
                    display: flex;
                }
                [data-open="false"] {
                    display: none;
                }
                .file-uploader__finder-toggle {
                    margin-left: 8px;
                }
            }
        }
        &-toggle {
            border-radius: 3px;
            border: 2px solid #000000;
            height: 49px;
            padding: 0 15px;
            background-color: #000000;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: #ffffff;
            cursor: pointer;
            * {
                pointer-events: none;
            }
            span {
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                i {
                    margin-left: 8px;
                }
            }
        }
    }
    &__tools {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    &__wrap {
        &--mode {
            height: 100%;
            width: 100%;
        }
        &--main {
            position: absolute;
            top: 15px;
            left: 15px;
            bottom: 15px;
            right: 15px;
            padding: 15px;
            background-color: #ffffff;
            color: #1a1b1c;
            &:before {
                content: "";
                opacity: 0;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: inherit;
                background-image: url("/admin/images/svg/loader.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 150px, 150px;
                pointer-events: none;
                z-index: 99;
                position: absolute;
            }
            &[data-theme="dark"] {
                background-color: #1a1b1c;
                color: #ffffff;
                .file-uploader__settings-options {
                    background-color: #333333;
                }
                .file-uploader__add-folder {
                    &-name {
                        border-color: #ffffff;
                    }
                    &-submit {
                        border-color: #ffffff;
                    }
                }
                .file-uploader__top-btn {
                    background-color: transparent;
                    color: #ffffff;
                    border-color: #ffffff;
                    &:hover {
                        color: #1a1b1c;
                        background-color: #ffffff;
                    }
                }
                .file {
                    &[data-used="load"] {
                        &:after {
                            background-color: rgba(26,27,28,0.8);
                        }
                    }
                    &[data-used="true"] {
                        &:after {
                            background-color: rgba(26,27,28,0.8);
                        }
                    }
                }
                .file-uploader__finder-toggle {
                    color: #1a1b1c;
                    background-color: #ffffff;
                    border: none;
                }
                .file-uploader__col--left:after {
                    background: #333333;
                }
                .file-uploader__pagination {
                    &-next, &-prev {
                        border-color: #ffffff;
                        &:hover {
                            background-color: #ffffff;
                            color: #1a1b1c;
                        }
                    }
                    &-item {
                        &:hover {
                            color: #1a1b1c;
                            background-color: #ffffff;
                        }
                        &.pagination-active {
                            color: #1a1b1c;
                            background-color: #ffffff;
                        }
                    }
                }
                .file-uploader__finder-input {
                    border: 2px solid #ffffff;
                    &:active, &:focus {
                        outline: none;
                    }
                }
                .file-uploader__finder-submit {
                    background-color: #ffffff;
                    color: #1a1b1c;
                    border: none;
                    &:after {
                        color: inherit;
                    }
                }
                .file-uploader__list-limit {
                    select {
                        border: 2px solid #ffffff;
                        background-color: transparent;
                    }
                    &:after {
                        color: #1a1b1c;
                        background-color: #ffffff;
                    }
                    &:active, &:focus {
                        outline: none;
                    }
                }
                .simplebar-track.simplebar-vertical {
                    right: 10px;
                    .simplebar-scrollbar {
                        opacity: 1;
                        &:before {
                            opacity: 1;
                            top: 0;
                            bottom: 0;
                            border-radius: 0;
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .file {
        &__icon {
            font-size: 170px;
            line-height: 170px;
            margin-bottom: 20px;
            text-align: center;
        }
        &__lock {
            display: none;
        }
        &[data-used="load"] {

            position: relative;
            cursor: default;
            pointer-events: none;
            user-select: none;

            &:after {
                content: "";
                pointer-events: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                position: absolute;
                background-image: url("/admin/images/svg/loader.svg");
                background-position: center 65px;
                background-repeat: no-repeat;
                background-size: 40px;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255,255,255,0.8);
            }
            .file__lock {
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                cursor: wait;
                display: block;
                z-index: 99999999;
                pointer-events: all;
            }
        }
        &[data-used="true"] {
            position: relative;
            cursor: default;
            pointer-events: none;
            user-select: none;
            * {
                user-select: none;
                pointer-events: none;
            }
            &:after {
                @extend .fa;
                @extend .fas;
                pointer-events: none;
                content: "\f00c";
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255,255,255,0.8);
                font-size: 40px;
                padding-bottom: 40px;
                color: #28a745;
            }
        }
    }
    &__list {
        &--folders {
            width: 100%;
            position: relative;
            z-index: 3;
            padding: 10px 10px 0;
            overflow: hidden;
            max-height: 100%;
            overflow: auto;
            ul, ol {
                margin: 0;
                padding: 0;
                list-style: none;
                width: 100%;
                li {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    padding-left: 15px;
                    &[data-level="0"] {
                        padding-left: 0;
                    }
                    &.active {
                        & > a {
                            color: #fff;
                            text-decoration: none;
                            &:before {
                                content: "\f07c";
                            }
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                    a {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        min-height: 25px;
                        color: inherit;
                        padding: 5px 0;
                        position: relative;
                        font-size: 13px;
                        &:before {
                            @extend .far;
                            content: "\f07b";
                            font-size: 16px;
                            margin-right: 6px;
                            margin-bottom: -1px
                        }
                        &:after {
                            content: "";
                            top: 0;
                            left: -100px;
                            right: -10px;
                            bottom: 0;
                            z-index: -1;
                            position: absolute;
                            background-color: #000;
                            opacity: 0;
                        }
                        &:hover {
                            color: #fff;
                            text-decoration: none;
                            &:after {
                                opacity: 1;
                            }
                            & > span {
                                right: 0;
                                left: auto;
                                display: block;
                            }
                        }
                        & > span {
                            position: absolute;
                            left: 105%;
                            white-space: nowrap;
                            width: auto;
                            z-index: 3;
                            top: 4px;
                            display: none;
                        }
                    }
                }
            }
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                width: 100%;
            }
            a {
                color: inherit;
                text-decoration: none;
            }
        }
        &--images {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            .file {
                width: 100%;
                padding: 0 10px;
                margin-bottom: 20px;
                width: 100%;
                cursor: pointer;
                position: relative;
                @media(min-width: 768px) {
                    max-width: 50%;
                }
                @media(min-width: 1025px) {
                    max-width: calc(100% / 3);
                }
                @media(min-width: 1280px) {
                    max-width: 25%;
                }
                @media(min-width: 1440px) {
                    max-width: 20%;
                }
                @media(min-width: 1800px) {
                    max-width: calc(100% / 6);
                }
                * {
                    pointer-events: none;
                }
                &__image {
                    margin-bottom: 10px;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                &__text {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    font-size: 13px;
                }
                &__sizes, &__name  {
                    width: 100%;
                    margin: 0;
                    p {
                        margin: 0;
                    }
                    .name {
                        font-weight: 700;
                    }
                }
                &__name {
                    margin-bottom: 5px;
                }
                &__sizes {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                }
                &[data-type="file"] {
                    .file__sizes {
                        p {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &__settings {
        &.open {
            .file-uploader__settings-options {
                display: block;
            }
        }
        &-icon {
            font-size: 30px;
            position: absolute;
            top: 15px;
            right: 60px;
            cursor: pointer;
            * {
                pointer-events: none;
            }

        }
        &.option-selected {
            .file-uploader__settings-option {
                display: none;
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
                &.selected {
                    display: flex;
                    .file-uploader__settings-option {
                        &-title {
                            padding-bottom: 8px;
                            margin-bottom: 8px;
                            border-bottom: 1px solid rgba(112,112,112,0.26);
                        }
                        &-values {
                            display: block;
                        }
                        &-title {
                            &--chevron {
                                transform: rotate(180deg);

                            }
                        }
                    }
                }
            }
        }
        &-option {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            border-bottom: 1px solid rgba(112,112,112,0.26);
            margin-bottom: 8px;
            padding-bottom: 8px;
            &-values {
                display: none;
                width: 100%;
            }
            &-value {
                cursor: pointer;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &-title {
                font-size: 18px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                width: 100%;
                cursor: pointer;
                * {
                    pointer-events: none;
                }
                &--text,
                &--icon,
                &--selected {
                    margin-right: 5px;
                }
                &--text {
                    margin-right: 10px;
                    display: inline-block;
                }
                &--chevron {
                    margin-left: auto;
                    margin-right: 0;
                    justify-self: flex-end;
                }
            }
        }
        &-options {
            display: none;
            background: #F3F3F3;
            position: absolute;
            padding: 15px;
            top: 75px;
            right: 15px;
            width: 100%;
            max-width: 250px;
            z-index: 3;
            border: 1px solid rgba(112,112,112,0.26);
        }
        @for $i from 1 through 10 {
            [data-selected="#{$i}"] {
                [data-select] {
                    display: none;
                }
                [data-select="#{$i}"] {
                    display: inline;
                }
            }
        }
    }
    &__pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        padding-top: 15px;
        &[data-state="first"] {
            .avt-image-popup__pagination-prev {
                opacity: 0.3;
                pointer-events: none;
            }
        }
        &[data-state="last"] {
            .avt-image-popup__pagination-next {
                opacity: 0.3;
                pointer-events: none;
            }
        }
        &[data-state="one"] {
            .avt-image-popup__pagination-prev,
            .avt-image-popup__pagination-next {
                opacity: 0.3;
                pointer-events: none;
            }
        }
        &-prev, &-next {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 76px;
            height: 76px;
            border: 2px solid #000000;
            cursor: pointer;
            border-radius: 3px;
            &:hover {
                background-color: #000000;
                color: #ffffff;
            }
            &:after {
                @extend .fa;
                content: "\f053";
            }
        }
        &-next {
            &:after {
                content: "\f054";
            }
        }
        &-item {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
            &.pagination-active, &:hover {
                background-color: #000000;
                color: #ffffff;
            }
        }
        &-items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
        }
    }
}
.file-add {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 6;
    flex-wrap: wrap;
    display: none;
    &[data-open="true"] {
        display: flex;
    }
    label {
        font-weight: 600 !important;
        margin: 0;
    }
    &__preview {
        width: 100%;
        max-width: calc(100% - 280px);
        background-color: transparent;
        position: relative;
        &-file {
            max-width: calc(100% - 30px);
            max-height: calc(100% - 30px);
            top: 15px;
            left: 15px;
            bottom: 15px;
            right: 15px;
            margin: auto;
            position: absolute;
        }
    }
    &__properties {
        background-color: #ffffff;
        color: #1a1b1c;
        width: 100%;
        max-width: 280px;
        padding: 70px 20px 20px;
    }
    &__checkbox {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        z-index: 5;
        opacity: 0;
        pointer-events: all;
        &:checked {
            & ~ .file-add__custom-checkbox {
                &:after {
                    @extend .fa;
                    @extend .fas;
                    content: "\f00c";
                    pointer-events: none;
                }
            }
        }
        &-wrap {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
        }
        &-text {
            user-select: none;
            font-weight: 600;
            font-size: 15px;
        }
    }
    &__custom-checkbox {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        border: 2px solid #1a1b1c;
        margin-right: 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        &:after {
            content: "";
            font-size: 14px;
        }
    }
    &__show-default {
        font-weight: 600;
        text-decoration: underline;
        color: #1a1b1c;
        margin-bottom: 15px;
        display: block;
    }
    &__add {
        width: 100%;
        height: 45px;
        background-color: #1a1b1c;
        color: #ffffff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 0.1em;
        &:hover {
            text-decoration: none;
        }
    }
    &__input {
        background-color: transparent;
        color: #1a1b1c;
        outline: none;
        border: none;
        border-radius: 4px;
        width: 100%;
        height: 45px;
        padding-left: 5px;
        &-text {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 15px;
        }
        &-wrap {
            margin-bottom: 15px;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
    }
}
[data-theme="dark"] {
    .file-add {
        &__preview {
            background-color: #333333;
        }
        &__properties {
            background-color: #1a1b1c;
            color: #ffffff;
        }
        &__add {
            background-color: #ffffff;
            color: #1a1b1c;
        }
        &__input {
            color: #ffffff;
            border: 2px solid #ffffff;

        }
        &__custom-checkbox {
            border-color: #ffffff;
        }
        &__show-default {
            color: #ffffff;
        }
    }
}
.files-list {
    &__icon {
        font-size: 60px;
        line-height: 60px;
        margin-right: 15px;
    }
    &__name {
        width: 270px;
        margin-right: 15px;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        flex-grow: 2;
    }
    &__description {
        width: auto;
        display: block;
        min-width: 250px;
        .text {
            font-size: 15px;
            margin-bottom: 3px;
        }
    }
}
