.order-list {
    font-family: "Inter", sans-serif;

    &__item {
        &-text {
            font-size: 14px;
        }

        &-status {
            padding: 4px 8px;
            border-radius: 5px;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.05em;
            font-weight: 400;
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;

            &--order {
                // created
                &[data-code="10"] {
                    background-color: #0079FF;
                }

                // success
                &[data-code="400"],
                &[data-code="500"] {
                    background-color: #29A64A;
                }

                // in progress
                &[data-code="300"],
                &[data-code="200"],
                &[data-code="100"],
                &[data-code="101"] {
                    background-color: #E67E22;
                }

                // error
                &[data-code="9"],
                &[data-code="102"] {
                    background-color: #DC3545;
                }
            }

            &--payment {
                // created
                &[data-code="0"] {
                    background-color: #0079FF;
                }

                // success
                &[data-code="400"] {
                    background-color: #29A64A;
                }

                // in progress
                &[data-code="100"],
                &[data-code="200"],
                &[data-code="300"] {
                    background-color: #E67E22;
                }

                // error
                &[data-code="500"] {
                    background-color: #DC3545;
                }
            }
        }

        &-link {
            &--edit {
                border: 2px solid #CED4DA;
                padding: 6px 14px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }
    }
}
