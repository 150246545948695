.error-message {
    font-size: 11px;
    line-height: 15px;
    div {
        margin-bottom: 10px;
    }
    &.show {
        margin-bottom: 20px;
    }
}
#raw_text_menuItems {
    min-height: 1000px;
}
#content_by_cat_menuItems {
    min-height: 600px;
}
#content_by_cat_params_catId {
    min-height: 400px;
}
